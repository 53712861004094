nav {
	background: #001433 !important;

	.brand-logo {
		font-family: 'Pacifico', cursive;
		color: #35448c;
		margin: 1rem 0;
		letter-spacing: 8px;
		font-size: 1.5em;
		@media only screen and (max-width: 1000px) {
			width: 100%;
		}
		@media only screen and (max-width: 500px) {
			font-size: 1em;
		}
	}
	ul {
		li {
			a {
				&:hover {
					color: #03a9f4;
				}
			}
		}
		&.right {
			align-items: center;
			padding: 1rem;
			text-transform: uppercase;
			letter-spacing: 3px;
		}
	}
}
#mobile-demo {
	background: #052a63;

	li {
		border-bottom: 1px solid #11418c;
		a {
			color: #f3f3f3;
		}
	}
}
