#home {
	background: #fafafa;
	#hero {
		background: #001433;
		    // background: linear-gradient(to right, #fafafa 50%, #001433 50%);
		// background-image: linear-gradient(
		// 		45deg,
		// 		rgba(0, 20, 51, 0.9) 0%,
		// 		#171917 100%
		// 	),
		// 	url('/img/backgroundMin.jpg');
		// background-size: cover;
		// background-position: center;
		// background-repeat: no-repeat;
		color: white;
		height: 100vh;

		.hero {
			&__background {
				img {
					max-width: 100%;
				}
			}
			&__title {
				display: flex;
				align-items: center;
				// flex-direction: column;
				height: 100%;
				justify-content: center;
				@media only screen and (max-width: 1000px) {
					flex-direction: column;
					position: relative;
					top: 100px;
				}
				a {
					box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.85),
						0 6px 20px 0 rgba(0, 0, 0, 0.86);
					&:hover {
						transform: translateY(5px);
						box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.85),
							0 6px 20px 0 rgb(108, 99, 255);
					}
				}
			}
			&__h1 {
				font-size: 5em;
				color: #d6d6e2;
				font-weight: 500;
				letter-spacing: 3px;
				// text-shadow: 2px 2px 4px rgb(0 0 0);
				// background: black;
				// padding: 5px 39px;
			}
			&__h2 {
				font-size: 1em;
				font-weight: 300;
				letter-spacing: 3px;
				padding: 1rem 0;
				color:#b4b4b5;
			}
		}
	}

.sectionTitle{
 &__container{
	 color:black;
	 text-transform: uppercase;
	 margin-bottom:5em;
	 text-align: center;
 }
 &__title{
	 font-size: 2.5em;
 display: inline-block;
 border-bottom: 1px solid #ffd14f;
 }
}
	// SKILLS SECTION
	#skills {
		padding: 7rem 0;
		img {
			max-width: 100%;
		}
		.skill {
			&__group {
				margin-bottom: 1.5em;
			}

		}

		h2 {
			text-align: center;
		}
		h3 {
			margin-bottom: 0.5rem;
			margin-bottom: 0.5rem !important;
		}
		h4 {
			margin-bottom: 0.5rem;
			margin-bottom: 0.5rem !important;
		}
	}

	//projects
	#projects {
		padding: 7% 0;
		background: #001433;
		background-image: linear-gradient(
				45deg,
				rgba(0, 20, 51, 0.9) 0%,
				#171917 100%
			),
			url('/img/backgroundMin.jpg');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		width: 100% !important;
		    margin: 0 auto;
		    display: block;
		    display: flex;
		    flex-direction: column;
		    /* justify-content: center; */
		    align-items: center;

				.projects_row {
				    display: flex;
				    flex-wrap: wrap;
				    justify-content: space-between; /* Adjust as needed */
				}

				.projects_row__item {
				    flex: 0 0 calc(33.33% - 20px); /* For 3 items per row with some spacing */
				    margin-bottom: 20px; /* Adjust as needed */
				}

				.projectBox {
				    display: flex;
				    flex-direction: column;
				    height: 100%;

				    &__imgContainer {
				        flex: 1; /* Take remaining vertical space */
				        display: flex;
				        justify-content: center;
				        align-items: center;
				        height: 200px; /* Set a fixed height for the image container */

				        img {
				            max-width: 100%; /* Ensure the image doesn't overflow horizontally */
				            max-height: 100%; /* Ensure the image doesn't overflow vertically */
				            object-fit: cover; /* Ensure the image covers the container without stretching */
				        }
				    }

				    &__description {
				        padding: 20px; /* Adjust padding as needed */
				        text-align: center; /* Align text in the center */
				    }
				}



	}
	#about {
		padding: 7% 0;
		img {
			max-width: 100%;
		}
		h2 {
			margin-bottom: 5rem;
		}
		p {
		}
	}
}
