$boxshadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
$transition: all 0.3s ease-in-out;
$boxshadow2:0px 10px 40px -5px rgba(0, 0, 0, 0.15);

//BREAKPOINTS
$breakpoint-materialNav: 992px;
$breakpoint-desktop1600: 1600px;
$breakpoint-desktop: 1200px;
$breakpoint-laptop: 950px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 450px;
