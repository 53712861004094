@import 'reset';
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Pacifico&display=swap');
@import 'global';
html{
	 scroll-behavior: smooth;
}
h1 {
	font-weight: 600;
	font-size: 2.5em;
	letter-spacing: 4px;
}
h2 {
	font-weight: 500;
	font-size: 1.7em;
	letter-spacing: 4px;
}
h3 {
	font-size: 1.4em;
	font-weight: 500 ;
	letter-spacing: 3px;
}
h4 {
	font-size: 1.2em;
	font-weight: 300;
	letter-spacing: 3px;
}

p {
	font-size: 1.2em;
	letter-spacing: 0.5px;
	line-height: 1.5;
	font-weight: 300;
}

strong {
    font-weight: bold;
}


a.waves-effect.waves-brown,.btn-large {
 /* The alpha value allows the text and background color
 of the button to still show through. */
	background: #6d5ff9  !important;
}
