// #single-project-container {
//     > div:nth-child(odd) {
//         background-color: #f3f3f3;
//
//     }
//
//     > div:nth-child(even) {
//         background-color: #ffffff;
//
//     }
// }

.single-project {
	    background-color: #fafafa;
	// position: relative;
	// top: 100px;
	.container {
		padding: 1em 0;
		@media only screen and (max-width: 1200px) {
		padding:1em;
		width:100%;
		}
	}
	.project {
		display: flex;
		justify-content: center;
		background: #fff;
  padding: 5em 0 15em 5em;
	height:700px;
	@media only screen and (max-width: 1200px) {
	padding:3em 1em;
	height:auto;
	}
&__buttons{
	a{
		box-shadow:$boxshadow2;
	}
}
	&__simpletrucker{
		height:750px;
		@media only screen and (max-width: 1200px) {
			height:auto
		}
	}
		@media only screen and (max-width: 1200px) {
			flex-direction: column;
		}
		&__buttons--button2{
			margin-left:1em;
		}
		&__button__container{
			display:flex;

		}

		h3 {
			opacity: 0.8;

			margin-top: 0.5rem;
			margin-bottom: 2rem;
		}
		&__box {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 10px;
			flex: 1;
			@media only screen and (max-width: 1200px) {
			    grid-template-columns: 1fr;
					padding:0em;
			}
			&__rightSection {
				padding-left: 10rem;

				@media only screen and (max-width: 1200px) {
					max-width: 90%;
					order: -1;
					h1 {
						font-size: 3em;
					}
					h3 {
						font-size: 1.5em;
					}
				}
				@media only screen and (max-width: 1200px) {
					max-width: 100%;
					padding-left: 0;
					h1 {
						font-size: 1.5em;
					}
					h3 {
						font-size: 1em;
					}
				}
			}
		}
		&__bio {
			margin: 2rem 0;
		}
		&__title {
		}
		&__images {
			position: relative;
			picture:first-child img {
				z-index: 1;
				position: absolute;
				top: 0px;
				// max-width: 73%;
				max-height:400px;
				box-shadow: $boxshadow2;
			        /* Additional styles or transitions can be added here */
			    }

			    picture:last-child img {
						z-index: 10;
						position: absolute;
						top: 135px;
// max-width: 84%;
						right: -91px;
						max-height:400px;
    		// box-shadow: 5px 5px 5px rgb(58 57 57 / 48%);
				box-shadow:$boxshadow2;
			        /* Additional styles or transitions can be added here */
			    }
			@media only screen and (max-width: 1200px) {
				padding: 4rem 0;
				img {
					&:first-child {
						top: 20%;
						max-width: 45%;
						margin: 0 auto;
						text-align: center;
						display: block;
					}
					&:last-child {
						z-index: 10;
						position: absolute;
						top: 32%;
						max-width: 53%;
						right: 0%;
						height: auto;
					}
				}
			}
			@media only screen and (max-width: 1200px) {
				display: none;
				padding: 4rem 0;
				img {
					&:first-child {
						top: 20%;
						max-width: 69%;
						margin: 0 auto;
						text-align: center;
						display: block;
					}
					&:last-child {
						z-index: 10;
						position: absolute;
						top: 117%;
						max-width: 57%;
						right: 0%;
						height: auto;
					}
				}
			}
		}
		&__skills {
			// padding: 3rem 0;

			h4 {
				margin-bottom: 0.5rem;
			}
		}
		&__skllsGroup {
			margin: 2rem 0;
		}
	}
}
#greyarea {
	background: #f3f3f3;
	padding: 7rem 0;
}

.projectBox {
    position: relative;
    // width: 300px; // Set a fixed width for the projectBox, adjust as needed
    // height: 200px; // Set a fixed height for the projectBox, adjust as needed
    overflow: hidden; // Hide overflowing content (projectBox__description)

    &__imgContainer {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__description {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    		background-color: rgb(29 161 243 / 65%);
        color: white;
        opacity: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: opacity 0.3s ease-in-out;
				p{
					background:white;
					color:black;
					padding:10px;
					border-radius: 5px 15px;
					// text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
				}
    }

    &:hover {
        .projectBox__description {
            opacity: 1;
        }
    }
}


.slider-container{
	display: none;
	img{
		max-width: 100%;
	}
		@media only screen and (max-width: 1200px) {
			display: block;
		}
}



.slider-container {
    width: 100%;
		overflow:unset;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS devices */
		img {
		    width: 100%;
		    height: auto;
		}
}



/* Existing styles for slider and slides remain unchanged */

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    cursor: pointer;
    user-select: none;
}

.left-arrow {
	left: 10px;
	    color: #28e6f6;
	    font-size: 2em;
			padding:20px
}

.right-arrow {
	right: 10px;
	    color: #28e6f6;
	    font-size: 2em;
				padding:20px
}



.slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}




.slider {
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
		    transition: transform 0.5s ease-in-out;
}

.slide {
    flex: 0 0 100%;
    scroll-snap-align: start;
}
