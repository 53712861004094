* {
	box-sizing: border-box;
}

body {
	font-family: 'Roboto', sans-serif;
	color: #000000;
	font-size: 1rem;
}

.containerWrap {
	max-width: 1400px;
	margin: 0 auto;
}

.paragraph__bold{
	font-weight:400;
}
